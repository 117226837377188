<template>
  <ag-charts-vue :options="options"></ag-charts-vue>
</template>
<script>
import { AgChartsVue } from 'ag-charts-vue';
export default {
  components: {
    'ag-charts-vue': AgChartsVue,
  },
  props: {
    fillArray: { type: Array, required: false },
    data: { type: Array, required: true },
    title: { type: String, default: '' },
    showPercentage: { type: Boolean, default: false },
    innerLabelSubtitle: { type: String, default: null },
    ausschlussTyp: { type: Array, default: () => [] },
  },
  data: function () {
    return {
      options: null,
    };
  },
  watch: {
    data(newData) {
      const options = { ...this.options };
      options.data = newData.filter(item => item.type !== 'Nicht akzeptiert');
      options.series[0].innerLabels[0].text = this.showPercentage
        ? this.percentage(this.accepted())
        : numFormatter.format(this.total());
      this.options = options;
    },
  },
  created() {
    this.options = {
      data: this.data.filter(item => item.type !== 'Nicht akzeptiert'),
      theme: {
        overrides: {
          baseTheme: 'ag-pastel',
        },
      },
      series: [
        {
          type: 'pie',
          calloutLabelKey: 'type',
          fillOpacity: 0.9,
          strokeWidth: 0.4,
          angleKey: 'count',
          sectorLabelKey: 'count',
          calloutLabel: {
            enabled: false,
          },
          title: {
            spacing: 15,
            text: this.title,
            showInLegend: false,
          },
          sectorLabel: {
            color: 'white',
            formatter: ({ datum, sectorLabelKey }) => {
              const value = datum[sectorLabelKey];
              const roundedPercent = Math.round((value / this.total()) * 100);
              return `${roundedPercent}%`;
            },
          },
          fills: this.fillArray,
          strokes: this.fillArray,
          innerRadiusRatio: 0.66,
          innerLabels: [
            {
              text: this.showPercentage
                ? this.percentage(this.accepted())
                : numFormatter.format(this.total()),
              fontSize: 16,
              fontWeight: 'bold',
            },
            {
              text: this.innerLabelSubtitle ? this.innerLabelSubtitle : 'Total',
              fontSize: 14,
            },
          ],
          highlightStyle: {
            item: {
              fillOpacity: 0.2,
              stroke: '#535455',
              strokeWidth: 1,
            },
          },
          tooltip: {
            renderer: ({ datum, calloutLabelKey, sectorLabelKey }) => {
              return (
                '<div class="my-3 mx-2">' +
                `${datum[calloutLabelKey]}: ${numFormatter.format(datum[sectorLabelKey])} (${Math.round(
                  (datum[sectorLabelKey] / this.total()) * 100
                )}%)` +
                '</div>'
              );
            },
          },
        },
      ],
      legend: {
        position: 'bottom',
        item: { maxWidth: 150 },
        spacing: 30,
      },
      overlays: {
        noData: {
          text: '',
        },
      },
    };
  },
  methods: {
    total() {
      return this.data.reduce((sum, d) => sum + d.count, 0);
    },
    percentage(accepted) {
      const total = this.total();
      const percentageAccepted = Math.round((accepted / total) * 100);
      return `${accepted} (${percentageAccepted}%)`;
    },
    accepted() {
      console.log(this.data.filter(item => !this.ausschlussTyp.includes(item.type)));
      return this.data
        .filter(item => !this.ausschlussTyp.includes(item.type))
        .reduce((sum, d) => sum + d.count, 0);
    },
  },
  computed: {},
};
const numFormatter = new Intl.NumberFormat('de-DE');
</script>

<style scoped>
.ag-default {
  border-radius: 50%;
}
</style>
